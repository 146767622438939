import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invoice"
    }}>{`Invoice`}</h1>
    <p>{`Customer Invoice adalah tanda tagihan kepada customer, dimana pada kegiatan ini terjadi pengakuan Piutang Customer. Customer Invoice ini dibuat berdasarkan Sales Order yang sudah berstatus “To Be Invoiced”. Sehingga item yang ditagihkan pada Invoice berupa SO dari masing-masing Customer.`}</p>
    <h2 {...{
      "id": "formulir-customer-invoice"
    }}>{`Formulir Customer Invoice`}</h2>
    <img src='/public/img/Invoice/Form Invoice.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-customer-invoice"
    }}>{`Kolom-kolom Pada Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Customer`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Nama Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Invoice`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Customer Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Syarat Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan jumlah hari sebelum jatuh tempo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field otomatis terisi sesuai perhitungan Tgl. Invoice ditambah dengan Syarat Pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "detail-tabel-customer-invoice"
    }}>{`Detail Tabel Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SO#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih Sales Order yang akan ditagihkan kepada Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl SO`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Tgl SO yang dipilih`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DO#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Nomor DO yang ada pada SO yang dipilih`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kendaraan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Nomor Polisi kendaraan yang digunakan, ketika menyelesaikan SO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Rute`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Rute sesuai SO yang dipilih`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Amount`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total daripada SO yang dipilih, namun masih bisa dilakukan perubahannya`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Pajak untuk setiap SO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Perhitungan total tagihan untuk setiap SO sebelum dikenakan Pajak`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "informasi-lainnya-pada-customer-invoice"
    }}>{`Informasi Lainnya Pada Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total amount sebelum pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan Pajak pada pesanan oleh Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total amount setelah ditambahkan pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Witholding`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah uang yang telah dibayarkan sebelumnya`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan yang masih belum dibayar oleh Customer`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "list-customer-invoice"
    }}>{`List Customer Invoice`}</h2>
    <p>{`Dokumen Invoice yang telah dibuat pada database, akan disimpan atau direkap dalam satu tabel yang disebut List Customer Invoice. List ini juga bisa dikatakan sebagai Overview daripada Customer Invoice`}</p>
    <p>{`List Customer Invoice dapat diakses dengan cara klik Piutang Pada `}<strong parentName="p">{`Side Menu > Customer Invoice`}</strong></p>
    <img src='/public/img/Invoice/List Invoice.png' />
    <p>{`Tampilan List Customer Invoice terbagi menjadi dua bagian yaitu :`}</p>
    <p><strong parentName="p">{`Tombol menu pada List Invoice, terdiri dari :`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Buat`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk membuat dokumen Invoice baru`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Import`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk menginputkan dokumen Invoice secara massal dalam sekali import`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Filter`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdapat dua jenis filter yaitu by Status dan by Periode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Search bar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field yang dapat digunakan untuk melakukan pencarian terhadap dokumen yang diinginkan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Atur Halaman`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigasi Next or Previous Page`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`List Customer Invoice Detail, terdiri dari :`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Invoice#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor Dokumen Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Customer`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nama pelanggan yang tertera pada Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Transaksi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal diterbitkannya Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal Jatuh Tempo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SO#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor SO yang terkandung pada Invoice. Ketika cursor diarahkan ke kolom tersebut akan muncul nomor-nomor SO pada Invoice tersebut`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl SO`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menunjukkan Tanggal SO pertama pada Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Pajak pada Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan pada Invoice setelah dikenakan Pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan yang belum dilakukan pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Status`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdiri dari empat status, yakni :`}<ul><li><strong parentName="td">{`Draft`}</strong><div>{`Dokumen bisa dilakukan perubahan`}</div></li><li><strong parentName="td">{`Open`}</strong><div>{`Dokumen yang sudah dilakukan Approval dan dapat dilakukan pembayaran`}</div></li><li><strong parentName="td">{`Paid`}</strong><div>{`Dokumen telah dibayar Lunas`}</div></li><li><strong parentName="td">{`Cancelled`}</strong><div>{`Dokumen dibatalkan setelah dilakukan Approval`}</div></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Warning ( `}<img src='/public/img/SO/warning.png' style={{
                "margin": "0"
              }} />{` )`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ikon tersebut muncul ketika Invoice telah melewati tanggal jatuh temponya`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-customer-invoice"
    }}>{`Membuat Customer Invoice`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Customer Invoice yang dapat digunakan sebagai transaksi jual beli kepada Customer :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Customer Invoice dengan klik Piutang Pada `}<strong parentName="p">{`Side Menu > Customer Invoice >`}</strong>{` Pada list Customer Invoice klik Tombol `}<strong parentName="p">{`Create`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Customer Invoice seperti gambar berikut :`}</p>
  <img src='/public/img/Invoice/Bikin Invoice.png' />
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Pilih Nama Customer`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Isi Tanggal diterbitkannya Invoice `}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Syarat Pembayaraan (Jumlah hari sebelum Jatuh tempo)`}</p>
            <img src='/public/img/Invoice/Bikin Invoice Detail.png' />
          </li>
          <li parentName="ol">
            <p parentName="li">{`Item yang ada pada Invoice merupakan Sales Order atas nama customer yang sudah dipilih dg status To Be Invoiced. Ada 2 cara dalam memilih SO yang akan dimasukkan dalam Invoice, yakni :`}</p>
            <ul parentName="li">
              <li parentName="ul">{`Memilih SO sesuai rentan tanggal sekian hingga sekian, dengan cara klik tombol filter pada kolom Tanggal SO > Masukkan rentan tanggal SO yang mau di masukkan dalam Invoice`}</li>
              <li parentName="ul">{`Select SO satu persatu pada kolom SO#`}</li>
            </ul>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan tagihan untuk setiap SO yang telah dipilih pada kolom Amount. (Field Amount ini awalnya otomatis terisi sesuai total tagihan pada dokumen SO, namun masih bisa diganti)`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan pajak untuk setiap SO jika diperlukan`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi sesuai dengan pesanan, klik tombol Save untuk menyimpan dokumen Invoice yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Invoice menjadi “Open”.`}</p>
        <p parentName="li">{`  `}<strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Customer Invoice ini di Confirm maka akan otomatis membentuk Jurnal Tipe Penjualan dengan status Posted dan status SO yang terkandung berubah status menjadi “Invoiced”`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "membatalkan-dokumen-setelah-approval"
    }}>{`Membatalkan Dokumen Setelah Approval`}</h2>
    <p>{`Pembatalan dokumen Customer Invoice yang dimaksud disini adalah tindakan yang membuat dokumen tersebut tidak dapat lagi dipakai sebagai dokumen transaksi tanpa menghapus dokumen tersebut. Sehingga dokumen Customer Invoice tersebut masih tetap bisa ditelusuri pada List Customer Invoice.`}</p>
    <p>{`Pembatalan dokumen Customer Invoice dapat dilakukan melalui langkah-langkah berikut :`}</p>
    <ol>
      <li parentName="ol">{`Buka List Customer Invoice ( Klik Piutang pada side menu > Customer Invoice )`}</li>
      <li parentName="ol">{`Cari Nomor Invoice yang dimaksud menggunakan fitur search, kemudian klik Nomor Invoicenya`}</li>
      <li parentName="ol">{`Klik dropdown Action > Cancel `}</li>
      <li parentName="ol">{`Kemudian setelah muncul pop up konfirmasi cancel dokumen klik tombol yes`}</li>
    </ol>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Untuk dokumen Customer Invoice yang dapat dibatalkan adalah dokumen yang statusnya “Open” dan belum ada sama sekali pembayaran. Tindakan ini juga akan membuat Jurnal yang terintegrasi akan berubah status menjadi Cancelled.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      